@import url(https://fonts.googleapis.com/css2?family=Antonio:wght@500&family=Spartan:wght@400;800&display=swap);
html,body,#root{
  width: 100%;  height: 100%; overflow: auto;
}

*{
  box-sizing: border-box;
  text-decoration: none;
  margin: 0px;

  /*드래그 금지*/
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/*선택 제거*/
*::selection {
  background-color:  rgba( 255, 255, 255, 0 );;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Spartan','Antonio','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400; font-size: 14px;  line-height: 25px;  color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center{
  display: flex;  justify-content: center;  align-items: center;
}

image{
  width: 100%;
  height: 100%; 
}

a:link { color: inherit; text-decoration: none;}
a:visited { color: inherit; text-decoration: none;}
a:hover { color: inherit; text-decoration: none;}

h1{
  font-family: 'Antonio', sans-serif; font-size: 80px;  line-height: 103px; font-weight: 500;
}

h2{
  font-family: 'Antonio', sans-serif; font-size: 40px;  line-height: 52px; font-weight: 500; word-spacing: -1.5px;
}

h3{
  font-family: 'Spartan', sans-serif; font-size: 12px;  line-height: 25px; font-weight: 700; word-spacing: 2.6px;
}

h4{
  font-family: 'Spartan', sans-serif; font-size: 11px;  line-height: 25px; font-weight: 700; word-spacing: 1px;
}
